import {useAppToast} from '~/stores/appToast';

export interface AccountSettings {
  firstName: string;
  lastName: string;
  locationId: string;
  email: string;
  dateOfBirth: string;
  phone: string;
}

export const useAccountSettingsStore = defineStore(
  'accountSettingsStore',
  () => {
    const {showSuccessToast, showApiErrorToast} = useAppToast();

    const accountSettings = ref<AccountSettings>();
    const accountSettingsServiceProviderCategories = ref<string[]>([]);

    function getAccountSettings(): AccountSettings | undefined {
      return accountSettings.value;
    }

    function setAccountSettings(settings: AccountSettings) {
      accountSettings.value = settings;
    }

    function fetchAccountProfileSettings() {
      return useGetJobApi('/account/settings', {
        method: 'GET',
        onResponseError: showApiErrorToast,
      });
    }

    function updateAccountSettings({
      locationId,
      phone,
    }: Partial<AccountSettings>) {
      return useGetJobApi('/account/settings', {
        method: 'POST',
        body: {
          locationId,
          phone,
        },
        onResponse: ({response}) => {
          if (response.status === 200) {
            const {t} = useI18n();
            showSuccessToast(
              t('stores.accountSettings.dataHasBeenUpdated_toast'),
            );
          }
        },
        onResponseError: showApiErrorToast,
      });
    }

    function fetchAccountServiceProviderProfileSettingsCategories() {
      return useGetJobApi('/account/service-provider/settings/categories', {
        method: 'GET',
        onResponseError: showApiErrorToast,
      });
    }

    function updateAccountServiceProviderProfileSettingsCategories(
      categoryIds: string[],
    ) {
      return useGetJobApi('/account/service-provider/settings/categories', {
        method: 'POST',
        body: {
          categoryIds,
        },
        onResponseError: showApiErrorToast,
      });
    }

    function $reset() {
      accountSettings.value = undefined;
      accountSettingsServiceProviderCategories.value = [];
    }

    return {
      setAccountSettings,
      getAccountSettings,
      fetchAccountProfileSettings,
      updateAccountSettings,
      fetchAccountServiceProviderProfileSettingsCategories,
      updateAccountServiceProviderProfileSettingsCategories,
      $reset,
    };
  },
);
